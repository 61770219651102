import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { isPC } from './utils/utils'

const Platform = React.lazy(() => import('./platform/index'));
const Website = React.lazy(() => import('./website/index'));

function App(): React.ReactElement {
  const { t } = useTranslation();
  React.t = t;
  React.log = (...value: any[]) => {
    let arr: any = [];
    for (let i = 0; i < value.length; i++) {
      arr.push(value[i])
    }
    console.log(arr);
  }
  React.platform = isPC();
  return <Switch>
    <Route exact path='/' component={Website} />
    <Route path="/platform" component={Platform} />
    <Route path='/website' component={Website} />
  </Switch>
}

export default App;
