// 节流
const Debounce = (func, wait = 500) => {
  let timeout;
  return function (event) {
    clearTimeout(timeout);
    event.persist && event.persist()
    timeout = setTimeout(() => {
      func(event)
    }, wait);
  };
}

const isPC = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  for (var i = 0; i < mobileAgents.length; i++) {
    if (userAgent.indexOf(mobileAgents[i]) > -1) {
      return false;
    }
  }

  return true;
}

function isRestricted(email) {
  const canonical = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  const domains = ["net", "cn", "com", "org", "pro", "info", "top", "vip", "com.cn", "net.cn", "gov.cn", "biz"];
  email = email.split('@').pop().toLowerCase();
  let emailArr = email.split('.')
  let suffix = ""
  if (emailArr.length > 1) {
    suffix = emailArr[1]
  } else {
    suffix = emailArr[0]
  }
  return !(!canonical.test(email) && domains.includes(suffix));
}


export { Debounce, isPC, isRestricted };